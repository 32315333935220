// noinspection JSValidateJSDoc, JSUnusedGlobalSymbols

import { connect } from 'react-redux';

import {
    LoginRequiredContainer as SecureLoginRequiredContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SecureComponent/LoginRequired/LoginRequired.container';

export {
    mapDispatchToProps,
    mapStateToProps,
} from 'SecureComponent/LoginRequired/LoginRequired.container';

// Empty component, because this is literally the only way I've found for
// implementing login required that isn't either hideously hacked or causing
// errors (especially the ScandiPWA inheritance bug.)

/** @namespace Lebonvin/PwaWitradis/Component/LoginRequired/Container */
export class LoginRequiredContainer extends SecureLoginRequiredContainer {}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRequiredContainer);
