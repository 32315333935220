// noinspection JSUnusedGlobalSymbols

import {
    CartReducer,
} from 'SourceStore/Cart/Cart.reducer';

export {
    CART_TOTALS,
    CartReducer,
    getInitialState,
    updateCartTotals,
    updateShippingPrice,
} from 'SourceStore/Cart/Cart.reducer';

export default CartReducer;
