// noinspection JSValidateJSDoc, JSUnusedGlobalSymbols

import { PropsWithChildren, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';

import LoginRequiredComponent from 'Component/LoginRequired/LoginRequired.component';
import {LoginRequiredProps} from 'Component/LoginRequired/LoginRequired.type';
import { RootState } from 'Util/Store/Store.type';

/** @namespace Lebonvin/PwaSecure/Component/LoginRequired/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): LoginRequiredProps => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Lebonvin/PwaSecure/Component/LoginRequired/Container/mapDispatchToProps */
export const mapDispatchToProps = (): object => ({});

/** @namespace Lebonvin/PwaSecure/Component/LoginRequired/Container */
export class LoginRequiredContainer extends PureComponent<PropsWithChildren<LoginRequiredProps>> {
    // static defaultProps: Partial<LoginRequiredProps> = {};

    containerProps(): PropsWithChildren<LoginRequiredProps> {
        const {
            children,
            isSignedIn,
        } = this.props;

        return {
            children,
            isSignedIn,
        };
    }

    render(): ReactElement {
        return (
            <LoginRequiredComponent { ...this.containerProps() } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRequiredContainer);
