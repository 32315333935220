// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import {
    SomethingWentWrongComponent as SourceSomethingWentWrongComponent,
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

/** @namespace Lebonvin/PwaBase/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrongComponent {
    // Override the something went wrong render, to ensure that we guarantee any
    // error gets logged, in case it is missed in other contexts.
    render(): ReactNode {
        const { errorDetails: { err} } = this.props;
        Sentry.captureException(err);

        return super.render();
    }
}

export default SomethingWentWrongComponent;
