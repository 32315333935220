// noinspection JSValidateJSDoc, JSUnusedGlobalSymbols

import { ReactNode } from 'react';

import LoginRequired from 'Component/LoginRequired';
import {
    AppComponent as SourceAppComponent,
} from 'SourceComponent/App/App.component';

/** @namespace Lebonvin/PwaSecure/Component/App/Component */
export class AppComponent extends SourceAppComponent {
    renderRouter(): ReactNode | null {
        const originalRouter: ReactNode = super.renderRouter();

        return (
            <LoginRequired>
                { originalRouter }
            </LoginRequired>
        );
    }
}

export default AppComponent;
