// noinspection JSValidateJSDoc, JSUnusedGlobalSymbols

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import ErrorHandler from 'Component/ErrorHandler';
import {DEFAULT_LOGO_HEIGHT, DEFAULT_LOGO_WIDTH} from 'Component/Header/Header.config';
import {
    ConfirmAccountPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    LoginAccountPage,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    RouterComponent as SourceRouterComponent,
    SendConfirmationPage,
    SomethingWentWrong,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    RouterAfterItemType,
    RouterBeforeItemType,
    RouterItemType,
    RouterSwitchItemType,
} from 'SourceComponent/Router/Router.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

export {
    PasswordChangePage,
    SendConfirmationPage,
    ConfirmAccountPage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    Footer,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    CookiePopup,
    DemoNotice,
    SomethingWentWrong,
    withStoreRegex,
};

export const Header = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'),
);

// For some reason we are getting multiple false positive errors with things
// like eslint spacing, etc. and imports not being used. Not clear indication
// why but these are demonstrably false positives.

/** @namespace Lebonvin/PwaSecure/Component/LoginRequiredRouter/Component */
export class LoginRequiredRouterComponent extends SourceRouterComponent {
    /* eslint-disable @typescript-eslint/indent */
    [RouterItemType.BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: RouterBeforeItemType.NOTIFICATION_LIST,
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: RouterBeforeItemType.DEMO_NOTICE,
        },
        {
            component: <Header logo_height={DEFAULT_LOGO_HEIGHT} logo_width={DEFAULT_LOGO_WIDTH} />,
            position: 20,
            name: RouterBeforeItemType.HEADER,
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: RouterBeforeItemType.NEW_VERSION_POPUP,
        },
    ];

    /* eslint-disable max-len */
    /* eslint-disable @typescript-eslint/indent */
    [RouterItemType.SWITCH_ITEMS_TYPE] = [
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ () => <PasswordChangePage /> } />,
            position: 60,
            name: RouterSwitchItemType.CHANGE_PASSWORD,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ () => <CreateAccountPage /> } />,
            position: 61,
            name: RouterSwitchItemType.CREATE_ACCOUNT,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ () => <LoginAccountPage /> } />,
            position: 62,
            name: RouterSwitchItemType.LOGIN,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ () => <ForgotPasswordPage /> } />,
            position: 63,
            name: RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ () => <SendConfirmationPage /> } />,
            position: 64,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ () => <ConfirmAccountPage /> } />,
            position: 65,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route render={ () => <LoginAccountPage /> } />,
            position: 1000,
            name: RouterSwitchItemType.LOGIN,
        },
    ];

    /* eslint-disable @typescript-eslint/indent */
    [RouterItemType.AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: RouterAfterItemType.FOOTER,
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: RouterAfterItemType.COOKIE_POPUP,
        },
    ];

    // Override to explicitly remove the potential for only displaying some elements.
    renderDefaultRouterContent(): ReactElement {
        const { setBigOfflineNotice } = this.props;

        return (
            <ErrorHandler setBigOfflineNotice={ setBigOfflineNotice }>
                <div block="Router" elem="MainItems">
                    { this.renderMainItems() }
                </div>
                { this.renderSectionOfType(RouterItemType.AFTER_ITEMS_TYPE) }
            </ErrorHandler>
        );
    }
}

export default LoginRequiredRouterComponent;
