// noinspection JSValidateJSDoc, JSUnusedGlobalSymbols

import {PropsWithChildren, PureComponent, ReactNode } from 'react';

import {LoginRequiredProps} from 'Component/LoginRequired/LoginRequired.type';
import LoginRequiredRouter from 'Component/LoginRequiredRouter';

import './LoginRequired.style';

/** @namespace Lebonvin/PwaSecure/Component/LoginRequired/Component */
export class LoginRequiredComponent extends PureComponent<PropsWithChildren<LoginRequiredProps>> {
    render(): ReactNode {
        const {
            children,
            isSignedIn,
        } = this.props;

        if (isSignedIn) {
            return children;
        }

        return (
            <div block="LoginRequired">
                <LoginRequiredRouter key="login-required-router" />
            </div>
        );
    }
}

export default LoginRequiredComponent;
